/* h1, */
/* p {
  font-family: Lato;
}
body {
  margin: 0;
} */
.gallery-item {
  margin: 5px;
}
