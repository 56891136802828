.container {
  box-shadow: rgb(0 0 0 / 5%) 1px 1px 30px 4px;
  transition: all 0.5s cubic-bezier(0.37, 0.01, 0, 0.98) 0s;
  background-color: white;
  cursor: pointer;
  border-width: 1px;
  border-color: transparent;
}

.container:hover {
  background-color: rgb(241, 249, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(201, 217, 248);
}
