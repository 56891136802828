.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    /* Allows table to resize based on content */
}

.table-bordered th,
.table-bordered td {
    padding: 0.5rem;
    width: 160px;
    max-width: 100%;
}

.table th,
.table td {
    border: 0;
    padding: 0.5rem;
    border-style: none;
}

@media (max-width: 768px) {

    .table-bordered th,
    .table-bordered td {
        width: auto;
        /* Make cells auto-size on smaller screens */
        display: block;
        /* Stack the rows */
        padding: 0.25rem;
        /* Reduce padding for small screens */
        text-align: left;
    }

    .table {
        display: block;
        overflow-x: auto;
        /* Enable horizontal scrolling for smaller screens */
    }

    .table th,
    .table td {
        width: 100%;
        display: block;
        /* Stack the cells vertically on smaller screens */
        padding: 0.5rem 0.25rem;
    }
}

@media (min-width: 769px) {

    .table-bordered th,
    .table-bordered td {
        width: 160px;
        /* Apply width for larger screens */
    }
}